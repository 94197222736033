export const menu = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Spotlight',
    path: '/spotlight',
  },
  {
    title: 'Garden Landscapes',
    path: '/garden-landscapes',
  },
  {
    title: 'Lawn Care',
    path: '/lawn-care',
  },
  {
    title: 'Tree Services',
    path: '/tree-services',
  },
];
